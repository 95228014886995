import React from 'react';
import {Version} from '../components/Version'

function Home() {
    return (
        <header className="App-header">
            <h1>{process.env.REACT_APP_ENV}.disposal-bins.com</h1>
            <p>
                hack the garbage!
            </p>
            <Version />
            <p>
                <strong>App Version</strong>: {process.env.REACT_APP_VERSION}<br/>
                <strong>App Build</strong>: {process.env.REACT_APP_GIT_SHA}<br/>
                <strong>App Environment</strong>: {process.env.REACT_APP_ENV}<br/>
                <strong>Node Environment</strong>: {process.env.NODE_ENV}
            </p>
        </header>
    );
}

export {Home};