import React, { useState, useEffect } from 'react';
import {Map} from '../components/Map'

function Locations() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [locationsData, setLocationsData] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/locations")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setLocationsData(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    return (
        <header className="App-header">
            <h1>Locations</h1>
            {error != null ? (
                <div>Error: {error.message}</div>
            ) : (
                !isLoaded ? (
                    <div>Loading...</div>
                ) : (
                    <Map locationsData={locationsData}/>
                )
            )}
        </header>
    );
}

export {Locations};