import './App.css';
import {Navbar} from './components/Navbar'
import {Home} from './pages/Home'
import {Locations} from './pages/Locations'
import {NotFound} from './pages/NotFound'
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/locations' element={<Locations />} />
          {/* More routes */}

          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
