import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {

  const navItems = [
    ["Home", "/"],
    ["Locations", "/locations"]
  ];

  return (
    <ul className="navbar-list">

      {navItems.map(navItem => (
        <li key={navItem[0]} className="nabar-item">
          <NavLink className="nabar-link" to={navItem[1]} activeclassname="active">{navItem[0]}</NavLink>
        </li>
      ))}

    </ul>
  );
}

  export {Navbar};