import React from 'react';

function NotFound() {
    return (
        <header className="App-header">
            <h1>404 Not Found</h1>
            <p>
                Oops, it looks like the page you're looking for has been disposed of...
            </p>
        </header>
    );
}

export {NotFound};