import React, { useState, useEffect } from 'react';

function Version() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [versionData, setVersionData] = useState([]);

    useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + "/version")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setVersionData(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])
  
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (  
        <p>
          <strong>API Version</strong>: {versionData.version}<br/>
          <strong>API Build</strong>: {versionData.commit}<br/>
          <strong>API Build Time</strong>: {versionData.build}<br/>
          <strong>API Environment</strong>: {versionData.environment}<br/>
        </p>
      );
    }
  }

  export {Version};