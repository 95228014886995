import React from 'react';
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

function Map(props) {
  Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';

  delete Leaflet.Icon.Default.prototype._getIconUrl;

  Leaflet.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  });

  var initialPosition = [49.8880, -119.4960]; //Kelowna - Could change to current location

  return (
    <MapContainer 
    center={initialPosition} 
    zoom={12} 
    scrollWheelZoom={false}
    style={{ height: '500px', width: '95%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {props.locationsData.map(locationData => (
        <Marker key={locationData.dataId} position={[locationData.gpsCoordinates.latitude, locationData.gpsCoordinates.longitude]}> 
          <Popup>
            <strong>{locationData.title}</strong> <br /> 
            {locationData.rating}/5 (based on {locationData.reviews} reviews).
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export {Map}